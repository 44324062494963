import React, { useContext } from "react";
import { AuthContext } from "../context/Context";
import { Navigate, useLocation } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const { isLogin } = useContext(AuthContext);
  let location = useLocation();
  // return isLogin ? <Route {...props} /> : <Navigate to="/login" />;
  if (!isLogin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
