import "./buy.css";

function Buy({ setBuy, type, ecommerce }) {
  return (
    <div className="buy">
      <div className="buy-wrapper">
        <div className="wrapper-img" onClick={() => setBuy(false)}>
          <img src="/assets/images/x.svg" alt="" />
        </div>
        <div className="buy-title">
          <h4>Belanja</h4>
          {type === "official" && <p>official Store</p>}
        </div>
        <div className="logo-toko-kontainer">
          {ecommerce.map((toko) => (
            <div className="logo-toko-wrapper" key={toko.id_ecommerce}>
              <a
                href={toko.link_ecommerce_official}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-tokopedia"
                  src={toko.logo_official}
                  alt={toko.nama_ecommerce}
                />
              </a>
              <span className="tokopedia">{toko.promo_ecommerce}</span>
            </div>
          ))}
          {/* <div className="logo-toko-wrapper">
            <img
              className="img-shopee"
              src="/assets/images/shopee.png"
              alt=""
            />
            <span className="shopee">Diskon 10%</span>
          </div> */}
          {/* <div className="logo-toko-wrapper">
            <img
              className="img-blibli"
              src="/assets/images/blibli.png"
              alt=""
            />
            <span className="blibli">Potongan Harga 15.000</span>
          </div> */}
          {/* <div className="logo-toko-wrapper">
            <img
              className="img-bukalapak"
              src="/assets/images/bukalapak.png"
              alt=""
            />
            <span className="bukalapak">Diskon 25%</span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Buy;
