import { useSnackbar } from "notistack";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";
import "./register.css";
import instance from "../../config/axios";

function Register() {
  const [nama, setNama] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [nohp, setNoHp] = useState("");
  const [, setRegister] = useState("");
  // const [is_subscribe, setIsSubscribe] = useState(0)

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      if (nama.length >= 5) {
        if (email.length > 7 && email.includes("@")) {
          if (nohp.length > 10 && nohp.length < 15 && isNaN(nohp) === false) {
            const res = await instance.post("api/register", {
              nama,
              email,
              password,
              nohp,
            });
            setRegister(res.data);
            enqueueSnackbar(res.data.msg, {
              variant: "success",
              // autoHideDuration: 2000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
            });
            navigate("/login");
          } else {
            enqueueSnackbar("Nomor ponsel diisi yang valid (10-15 digit)", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Email harus yang valid", { variant: "error" });
        }
      } else {
        enqueueSnackbar("Minimal panjang nama 5 karakter", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("mohon periksa lagi datanya", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  };
  return (
    <div className="register">
      <GoToTop />
      <Helmet>
        <title>Register | Adem Sari</title>
        <meta name="description" content="Register website Adem Sari" />
      </Helmet>

      <DaunJatuh />
      <div className="register-container">
        <div className="register-kontents-wrapper">
          <div className="register-title">
            <h4 style={{ color: "white" }}>Daftar</h4>
          </div>
          <form onSubmit={handleRegister} className="form-register">
            <div className="group-register">
              <label htmlFor="name">Nama</label>
              <input
                type="text"
                id="nama"
                placeholder="Nama kamu"
                onChange={(e) => setNama(e.target.value)}
              />
            </div>
            <div className="group-register">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="Email kamu"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="group-register">
              <label htmlFor="nomor-telepon">Nomor Telepon</label>
              <input
                type="text"
                id="phone"
                placeholder="Nomor Telepon"
                onChange={(e) => setNoHp(e.target.value)}
              />
            </div>
            <div className="group-register">
              <label htmlFor="password">Kata Sandi</label>
              <input
                type="password"
                id="password"
                placeholder="Kata sandi kamu"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-wrapper-register">
              <button type="submit">Daftar</button>
            </div>
          </form>
          <div className="keterangan-tambahan">
            <p>
              Belum Punya Akun?{" "}
              <Link to="/login">
                <span>Masuk</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
