import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";
import { AuthContext } from "../../context/Context";
import "./login.css";
import instance from "../../config/axios";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [params] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { setLogin, setUser } = useContext(AuthContext);

  const checkLogin = () => {
    const nama = localStorage.getItem("nama");
    const token = localStorage.getItem("token");
    if (nama && token) {
      setLogin(true);
      setUser({ nama, token });
      navigate("/profil");
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await instance.post("/api/login", {
        email,
        password,
      });
      localStorage.setItem("token", res.data.success.token);
      localStorage.setItem("nama", res.data.success.nama);
      enqueueSnackbar("berhasil login", {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
      setLogin(true);
      setUser(res.data.success);
      navigate("/profil");
    } catch (error) {
      enqueueSnackbar(error.response.data.error, {
        variant: "error",
        autoHideDuration: 5000,
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  };
  useEffect(() => {
    checkLogin();
  });

  useEffect(() => {
    const verificationNotif = () => {
      const param = params.get("msg");
      if (param) {
        enqueueSnackbar(param, {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }
    };
    verificationNotif();
  }, [enqueueSnackbar, params]);

  return (
    <div className="login">
      <GoToTop />
      <Helmet>
        <title>Login | Adem Sari</title>
        <meta name="description" content="Login website Adem Sari" />
      </Helmet>
      <div>{JSON.stringify(params)}</div>
      <DaunJatuh />
      <div className="login-container">
        <div className="login-kontents-wrapper">
          <div className="login-title">
            <h4 style={{ color: "white" }}>Masuk</h4>
          </div>
          <form className="form-login" onSubmit={handleLogin}>
            <div className="group-login">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="Email kamu"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="group-login">
              <label htmlFor="password">Kata Sandi</label>
              <input
                type="password"
                id="password"
                placeholder="Kata sandi kamu"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-wrapper-login">
              <button type="submit">Masuk</button>
            </div>
            {/* <button onClick={() => setLogin(true)}>test</button> */}
          </form>
          <div className="keterangan-tambahan-login">
            <p>
              Belum Punya Akun?{" "}
              <Link to="/register">
                <span>Daftar</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
