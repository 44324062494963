import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./promo.css";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import Loading from "../../components/loading/Loading";
import { FormatDate } from "../../helpers/formatDate";
import instance from "../../config/axios";

function Promo() {
  const [promo, setPromo] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("promo :>> ", promo);
  useEffect(() => {
    setLoading(true);
    const fetchPromo = async () => {
      const res = await instance.get("api/promo");
      try {
        setPromo(res.data);
        setLoading(false);
      } catch (error) {
        console.log("error :>> ", error);
      }
    };

    fetchPromo();

    return () => {
      setPromo([]);
    };
  }, []);

  return (
    <div className="promo">
      <Helmet>
        <title>Promo | Adem Sari</title>
        <meta
          name="description"
          content="Berbagai Promo menarik dari Adem Sari"
        />
      </Helmet>

      <DaunJatuh />
      <div
        className={
          promo.length > 0 ? "promo-container" : "promo-container-kosong"
        }
      >
        <div className="promo-header">
          <div className="promo-title">
            <h3>Promo</h3>
          </div>
        </div>
        {/* ISI PROMO */}

        <div className="promo-kontents">
          {loading && <Loading loading={loading} />}
          {promo &&
            promo.map((item) => (
              <div className="promo-kontents-wrapper" key={item.id_promo}>
                <div className="foto-promo">
                  <img src={item.foto_promo} alt="promo adem sari" />
                </div>
                <div className="detail-promo">
                  <div className="nama-promo">
                    <h5>{item.manfaat_promo}</h5>
                  </div>
                  <div className="periode-promo">
                    <div className="img-periode">
                      <img src="assets/images/ikon-periode.svg" alt="" />
                    </div>
                    <div className="detail-periode">
                      <span>Periode Promo</span>
                      <p>
                        {FormatDate(item.mulai_promo)} {" - "}
                        {FormatDate(item.selesai_promo)}
                      </p>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    {/* <button> */}
                    <a href={item.link_promo} target="_blank" rel="noreferrer">
                      Lihat Detail
                    </a>
                    {/* </button> */}
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="promo-kontents-wrapper">
            <div className="foto-promo">
              <img src="assets/images/promo-shopee.png" alt="" />
            </div>
            <div className="detail-promo">
              <div className="nama-promo">
                <h5>Banyak belanja Adem Sari banyak juga dapat Diskonnya</h5>
              </div>
              <div className="periode-promo">
                <div className="img-periode">
                  <img src="assets/images/ikon-periode.svg" alt="" />
                </div>
                <div className="detail-periode">
                  <span>Periode Promo</span>
                  <p>20 - 30 Desember 2021</p>
                </div>
              </div>
              <div className="button-wrapper">
                <button>Lihat Detail</button>
              </div>
            </div>
          </div> */}
          {/* <div className="promo-kontents-wrapper">
            <div className="foto-promo">
              <img src="assets/images/promo-bukalapak.png" alt="" />
            </div>
            <div className="detail-promo">
              <div className="nama-promo">
                <h5>Banyak belanja Adem Sari banyak juga dapat Diskonnya</h5>
              </div>
              <div className="periode-promo">
                <div className="img-periode">
                  <img src="assets/images/ikon-periode.svg" alt="" />
                </div>
                <div className="detail-periode">
                  <span>Periode Promo</span>
                  <p>20 - 30 Desember 2021</p>
                </div>
              </div>
              <div className="button-wrapper">
                <button>Lihat Detail</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Promo;
