import { useEffect, useState } from "react";
// import { useContext } from "react";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import instance from "../../config/axios";
// import { AuthContext } from "../../context/Context";
import "./profil.css";
import { useSnackbar } from "notistack";

function Profil() {
  const [tabProfil, setTabProfil] = useState(true);
  const [tabPoin, setTabPoin] = useState(false);
  const [tabVoucher, setTabVoucher] = useState(false);
  // const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [voucher, setVoucher] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleProfil = () => {
    setTabProfil(true);
    setTabPoin(false);
    setTabVoucher(false);
  };
  const handlePoin = () => {
    setTabProfil(false);
    setTabPoin(true);
    setTabVoucher(false);
  };
  const handleVoucher = () => {
    setTabProfil(false);
    setTabPoin(false);
    setTabVoucher(true);
  };
  const getDataUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await instance.get("api/profile", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(true);
      setDataUser(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getVoucher = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await instance.get("api/voucher/user", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVoucher(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const copyVoucher = async (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(text + " berhasil di copy di clipboard", {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
    });
  };
  useEffect(() => {
    getDataUser();
    getVoucher();
    setLoading(false);
  }, []);

  // let data = [
  //   {
  //     tanggal: "27 Desember 2021",
  //     kode: "admsr123",
  //     status: "sudah ditukarkan",
  //     poin: "200",
  //   },
  //   {
  //     tanggal: "26 Desember 2021",
  //     kode: "admsr123",
  //     status: "belum ditukarkan",
  //     poin: "200",
  //   },
  //   {
  //     tanggal: "25 Desember 2021",
  //     kode: "admsr123",
  //     status: "kadaluarsa",
  //     poin: "0",
  //   },
  // ];

  let data = [
    {
      tanggal: "-",
      kode: "-",
      status: "sudah ditukarkan",
      poin: "",
    },
    {
      tanggal: "-",
      kode: "-",
      status: "belum ditukarkan",
      poin: "",
    },
  ];

  // const classWarna = (status) => {
  //   if (status === "kadaluarsa") {
  //     return "merah";
  //   }
  //   if (status === "belum ditukarkan") {
  //     return "kuning";
  //   }
  //   if (status === "sudah ditukarkan") {
  //     return "kuning-bg";
  //   }
  // };

  // console.log("classWarna(data[0].status) :>> ", classWarna(data[0].status));

  return (
    <div className="profil">
      <DaunJatuh />
      <div className="profil-container">
        <div className="detail-tab-profil">
          <button onClick={handleProfil} className={`${tabProfil && "active"}`}>
            Profil
          </button>
          <button onClick={handlePoin} className={`${tabPoin && "active"}`}>
            Poin Loyalti
          </button>
          <button
            onClick={handleVoucher}
            className={`${tabVoucher && "active"}`}
          >
            Voucher
          </button>
        </div>
        {tabProfil && loading && (
          <div className="wrapper-profil">
            <div className="logo-profil">
              <div className="figure-image">
                <img src="/assets/images/people.svg" alt="" />
              </div>
              <button>Edit Profil</button>
            </div>
            <div className="isi-profil">
              <div className="group-input">
                <label htmlFor="nama">Nama</label>
                <input
                  type="text"
                  id="nama"
                  placeholder={dataUser?.nama}
                  disabled
                />
              </div>
              <div className="group-input">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder={dataUser?.email}
                  disabled
                />
              </div>
              {/* <div className="group-input">
                <label htmlFor="jenis-kelamin">Jenis Kelamin</label>
                <input type="text" id="jenis-kelamin" placeholder="Perempuan" />
              </div> */}
              <div className="group-input">
                <label htmlFor="no-telepon">No. Telepon</label>
                <input
                  type="text"
                  id="no-telepon"
                  placeholder={dataUser?.nohp}
                  disabled
                />
              </div>
            </div>
          </div>
        )}
        {tabPoin && (
          <div className="wrapper-poin">
            <div className="logo-poin">
              <div className="figure-image">
                <img src="/assets/images/people.svg" alt="" />
              </div>
              <div className="jumlah-poin">
                {/* <span className="poin-angka">1700</span>
                <span className="poin-kata">points</span> */}
                {/* <span className="poin-angka">belum ada point</span> */}
                <span className="poin-kata">belum ada points</span>
              </div>
              <div className="jenis-member">
                <img src="/assets/images/gold.svg" alt="" />
                <span className="tipe-member">gold member</span>
              </div>
            </div>
            <div className="input-poin">
              <input type="text" placeholder="masukkan kode voucher" />
            </div>
            <div className="kontent-poin">
              {/* Hide in mobile */}
              <div className="header-table">
                <div className="group__input">
                  <div className="tanggal">Tanggal</div>
                  <div className="kode-voucher">Kode Voucher</div>
                </div>
                <div className="group__input">
                  <div className="status">Status</div>
                  <div className="poin">Poin</div>
                </div>
              </div>
              {data.map((item, idx) => (
                <div className="isi-table" key={idx}>
                  <div className="group__input">
                    <div className="isi-tanggal">{item.tanggal}</div>
                    <div className="isi-kode-voucher">{item.kode}</div>
                  </div>
                  <div className="group__input">
                    {/* <div className={`isi-status ${classWarna(item.status)}`}> */}
                    <div className={`isi-status`}>{/* {item.status} */}-</div>
                    <div className="isi-poin">+{item.poin}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* {tabVoucher && (
          <div className="wrapper-voucher">
            <span>Belum ada Voucher...</span>
          </div>
        )} */}

        {tabVoucher &&
          (voucher.length !== 0 ? (
            <div className="wrapper-voucher">
              {voucher.map((el, idx) => (
                <div className="voucher-kontents-wrapper" key={idx}>
                  <div className="foto-voucher">
                    <img src={el.image} alt="voucher adem sari" />
                  </div>
                  <div className="detail-voucher">
                    <div className="nama-voucher">
                      <h5>{el.kode_unik}</h5>
                    </div>
                    <div className="voucher-button-wrapper">
                      {/* <button>Tukar</button> */}
                      <button onClick={() => copyVoucher(el.kode_unik)}>
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="wrapper-voucher">
              <span>Belum ada Voucher...</span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Profil;
