import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Buy from "../buy/Buy";
import "./ads.css";

function Ads({ produk }) {
  const [buyRekomendasi, setBuyRekomendasi] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="ads-kontents-wrapper">
        <div className="rekomendasi">
          <h5>Rekomendasi</h5>
        </div>
        <div className="foto-ads">
          <img src={produk.foto_produk} alt="" className="sachet" />
        </div>
        <div className="nama-ads">
          <h5>{produk.nama_produk}</h5>
        </div>
        <div className="button-ads">
          <div className="image-wrapper">
            <img
              src="/assets/images/see.svg"
              alt=""
              onClick={() => navigate(`/produk/${produk.slug}`)}
            />
            <img
              src="/assets/images/cart.svg"
              alt=""
              onClick={() => setBuyRekomendasi(true)}
            />
          </div>
        </div>
      </div>
      {buyRekomendasi && (
        <Buy
          type="produk"
          setBuy={setBuyRekomendasi}
          ecommerce={produk.ecommerce_produk}
        />
      )}
    </>
  );
}

export default Ads;
