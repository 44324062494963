import { useEffect, useState } from "react";
import "./daun-jatuh.css";

function DaunJatuh() {
  const [openDaun, setOpenDaun] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenDaun(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      {openDaun && (
        <div className="daun-wrapper">
          <img src="/assets/images/Daun.gif" alt="" />
        </div>
      )}
    </>
  );
}

export default DaunJatuh;
