import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import "./promo-menarik.css";
import { useWindow } from "../../helpers/useWindow";
import instance from "../../config/axios";

function PromoMenarik() {
  // const [promo, setPromo] = useState(false);
  // USESTATE FETCH
  // const [error, setError] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [promos, setPromos] = useState([]);
  // console.log("promos :>> ", promos);

  //NAVIGATE
  const navigate = useNavigate();

  // FETCH PROMO
  // setLoading(true);
  const fetchPromos = async () => {
    try {
      const res = await instance.get("api/produk_promoted");
      // let random = Math.ceil(Math.random() * res.data.length);
      // console.log("random :>> ", random);
      // const filterPromo = res.data.filter((produk) => produk.id_produk === 13);
      setPromos(res.data);
      // setLoading(false);
    } catch (error) {
      console.log("error ini :>> ", error);
      // setError(true);
    }
  };

  useEffect(() => {
    fetchPromos();

    return () => {
      setPromos([]); // This worked for me
    };
  }, []);

  // GSAP SCROLL
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    // gsap.from(".promo-menarik", {
    //   y: 120,
    //   duration: 3,
    // });

    gsap.fromTo(
      ".promo-menarik",
      { y: 120 },
      {
        y: 0,
        scrollTrigger: {
          trigger: document.querySelector(".intro"),
          start: "center center",
          end: "bottom center",
          scrub: true,
        },
      }
    );

    gsap.from(".daun-jatuh", {
      display: "none",
      scrollTrigger: {
        trigger: document.querySelector(".promo-menarik"),
        start: "center center",
        end: "bottom center",
        scrub: true,
      },
    });
  }, []);
  // Audio
  const { isPlay } = useContext(Context);

  let waterfall3 = useMemo(
    () => new Audio("/assets/images/waterfall-3.mp3"),
    []
  );

  if (isPlay) {
    waterfall3.play();
    waterfall3.muted = false;
  } else {
    waterfall3.pause();
    waterfall3.muted = true;
  }

  useEffect(() => {
    const handleOut = () => {
      waterfall3.volume = 0;
    };

    const handleOver = () => {
      waterfall3.volume = 1;
    };

    const promo = document.querySelector(".promo-menarik");
    promo.addEventListener("mouseout", handleOut);
    promo.addEventListener("mouseover", handleOver);

    const produk = document.querySelector(".produk-kami");
    produk.addEventListener("mouseout", handleOut);
    produk.addEventListener("mouseover", handleOver);

    return () => {
      promo.removeEventListener("mouseout", handleOut);
      promo.removeEventListener("mouseover", handleOver);
      produk.removeEventListener("mouseout", handleOut);
      produk.removeEventListener("mouseover", handleOver);
    };
  }, [waterfall3]);

  // SET MOBILE
  let layer = useWindow();

  let imageBackground;
  if (layer >= 768) {
    imageBackground = "/assets/images/BG_2.webp";
  } else {
    imageBackground = "/assets/images/BG_2mobile.png";
  }

  return (
    <div className="promo-menarik">
      <img src={imageBackground} alt="promo" />
      <img src="/assets/images/Daun.gif" className="daun-jatuh" alt="Daun" />
      <div className="promo-menarik-container">
        <div className="promo-menarik-contents">
          <h3>Promo Menarik</h3>
          <div className="icon-promo">
            <img src="/assets/images/icon-promo.svg" alt="" />
          </div>
          {promos?.map((produk) => (
            <div className="box-produk" key={produk.id_produk}>
              <img src={produk.foto_produk} alt="" />
              <h4>{produk.nama_produk}</h4>
              <p>{produk.nama_discount}</p>
              <button onClick={() => navigate(`/produk/${produk.slug}`)}>
                view more
                <div className="plus">+</div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PromoMenarik;
