import { useEffect, useState } from "react";
import "./produk-kami.css";
import Loading from "../loading/Loading";
import { useWindow } from "../../helpers/useWindow";
import PopUpProduk from "../popup-produk/PopUpProduk";
import instance from "../../config/axios";

function ProdukKami() {
  // USESTATE FETCH
  // const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allProduk, setAllProduk] = useState([]);
  // console.log("allProduk :>> ", allProduk);

  // POPUP PRODUK
  const [typeProduk, setTypeProduk] = useState("");

  // FETCH PRODUK LIMIT
  // setLoading(true);
  const fetcAllProdukKami = async () => {
    setLoading(true);
    const res = await instance.get("api/produk/all/limit");
    try {
      setAllProduk(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error allProduk :>> ", error);
      setLoading(false);
      // setError(true);
    }
  };

  useEffect(() => {
    fetcAllProdukKami();

    return () => {
      setAllProduk([]); // Clear
    };
  }, []);

  // SET MOBILE
  let layer = useWindow();

  let imageBackground;
  if (layer >= 768) {
    imageBackground = "/assets/images/BG_33.png";
  } else {
    imageBackground = "/assets/images/BG_3mobile.png";
  }

  return (
    <div className="produk-kami">
      <img src={imageBackground} alt="intro" />
      <div className="air-terjun3">
        <img src="/assets/images/air-terjun-33.gif" alt="" />
      </div>
      <div className="air-terjun2">
        <img src="/assets/images/air-terjun-22.gif" alt="" />
      </div>
      <div className="produk-kami-container">
        <div className="produk-kami-contents">
          <h3>Produk Kami</h3>
        </div>
      </div>
      <div className="produk-kami-container-produk">
        <div className="produk-kami-contents-produk">
          {loading && <Loading loading={loading} />}
          {allProduk &&
            allProduk.map((produk) => (
              <div className="produk-group" key={produk.id_produk}>
                <div className="produk-group-png">
                  <img
                    src={produk.foto_produk}
                    alt={produk.nama_produk}
                    className="png"
                  />
                </div>
                <div
                  className="produk-group-svg"
                  onClick={() => setTypeProduk(produk.type_produk_id)}
                >
                  <img
                    src={produk.foto_tipe_minuman}
                    alt={produk.tipe_minuman}
                    className="svg"
                  />
                  <p>{produk.tipe_minuman}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {typeProduk && (
        <PopUpProduk setTypeProduk={setTypeProduk} typeProduk={typeProduk} />
      )}
    </div>
  );
}

export default ProdukKami;
