import { Link } from "react-router-dom";
import "./not-found.css";

function NotFound() {
  return (
    <div className="not-found">
      <p>404 - Tidak ada halaman yang dicari!</p>
      <p style={{ color: "yellow" }}>
        <Link to={"/"}>Back to home!</Link>{" "}
      </p>
    </div>
  );
}

export default NotFound;
