import { Routes, Route } from "react-router-dom";
import "./app.css";
import Navbar from "./components/navbar/Navbar";
import Beranda from "./pages/beranda/Beranda";
import Produk from "./pages/produk/Produk";
import TentangKami from "./pages/tentang-kami/TentangKami";
import Artikel from "./pages/artikel/Artikel";
import Promo from "./pages/promo/Promo";
import Login from "./pages/login/Login";
import Footer from "./components/footer/Footer";
import NotFound from "./components/not-found/NotFound";
import Register from "./pages/register/Register";
import DetailProduk from "./pages/detail-produk/DetailProduk";
import DetailArtikel from "./pages/detail-artikel/DetailArtikel";
import HubungiKami from "./pages/hubungi-kami/HubungiKami";
import KebijakanKami from "./pages/kebijakan-kami/KebijakanKami";
import Profil from "./pages/profil/Profil";
import ProtectedRoute from "./components/ProtectedRoute";
// import { KonfirmasiEmail } from "./pages/konfirmasi-email/KonfirmasiEmail";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Beranda />} />
        <Route path="produk" element={<Produk />} />
        <Route path="produk/:slug" element={<DetailProduk />} />
        <Route path="tentang-kami" element={<TentangKami />} />
        <Route path="media" element={<Artikel />} />
        <Route path="media/:category/:slug" element={<DetailArtikel />} />
        <Route path="promo" element={<Promo />} />
        <Route path="register" element={<Register />} />
        <Route path="hubungi-kami" element={<HubungiKami />} />
        <Route path="kebijakan-kami" element={<KebijakanKami />} />
        <Route path="*" element={<NotFound />} />
        <Route path="login" element={<Login />} />
        {/* <Route path="konfirmasi-email" element={<KonfirmasiEmail />} /> */}
        <Route
          path="profil"
          element={
            <ProtectedRoute>
              <Profil />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
