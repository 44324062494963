import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./footer.css";

function Footer() {
  const [year, setYear] = useState(null);
  useEffect(() => {
    const objectDate = new Date();
    const year = objectDate.getFullYear();
    setYear(year);
  }, []);
  return (
    <div className="footer-container">
      <div className="footer-contents">
        <div className="sosmed-container">
          <div className="sosmed" title="0800-1-282828">
            <a
              // href="https://wa.me/628001282828?text=I'm%20interested%20in%20your%20products"
              href="tel:08001282828"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/images/call.svg" alt="call" />
            </a>
          </div>
          <div className="sosmed" title="customer.service@enesis.com">
            <Link to="/hubungi-kami">
              <img src="/assets/images/email.svg" alt="email" />
            </Link>
          </div>
          <div className="sosmed">
            <a
              href="https://www.facebook.com/AdemSariID/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/images/fb.svg" alt="fb" />
            </a>
          </div>
          <div className="sosmed">
            <a
              href="https://www.instagram.com/ademsari_id/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/images/ig.svg" alt="ig" />
            </a>
          </div>
          <div className="sosmed">
            <a
              href="https://www.youtube.com/user/AdemSariID"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/images/youtube.svg" alt="youtube" />
            </a>
          </div>
          <div className="sosmed">
            <a
              href="https://twitter.com/ademsari_id"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/images/Twitter.svg" alt="twitter" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-contents">
        <div className="navigasi-container">
          <div className="logo-footer">
            <Link to="/">
              <img src="/assets/images/logo.png" alt="" />
            </Link>
          </div>
          <div className="nav-footer">
            <ul>
              <li>
                <NavLink to="produk">Produk</NavLink>
              </li>
              <li>
                <NavLink to="/hubungi-kami">Hubungi Kami</NavLink>
              </li>
              <li>
                <NavLink to="/kebijakan-kami">Kebijakan Kami</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-contents">
        <div className="garis-panjang"></div>
      </div>
      <div className="footer-contents">
        <div className="copy-right">
          <div className="copy-right-adem-sari">
            <span>Copyright ©{year} Adem Sari</span>
          </div>
          <div className="copy-right-alamat">
            <span>
              PT. Sari Enesis Indah, Jl. Kruing I Blok L5 No. 5, Delta Silicon
              Industrial Estate, Bekasi 17550
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
