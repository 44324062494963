const Reducer = (state, action) => {
  switch (action.type) {
    case "ON":
      return {
        isPlay: true,
      };
    case "OFF":
      return {
        isPlay: false,
      };

    default:
      return state;
  }
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return {
        ...state,
        isLogin: action.data,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.data,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.data,
      };
    default:
      return state;
  }
};

export default Reducer;
