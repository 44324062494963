import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./artikel.css";
import InfoKamus from "../../components/info-kamu/InfoKamus";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";
import instance from "../../config/axios";

function Artikel() {
  // USESTATE FETCH
  // const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allArtikel, setAllArtikel] = useState([]);
  const [filteredData, setFilteredData] = useState(allArtikel);

  // FETCH ARTIKEL ALL
  // setLoading(true);
  const fetchArtikelAll = async () => {
    setLoading(true);
    try {
      const res = await instance.get("api/artikel/all");
      setAllArtikel(res.data);
      setFilteredData(res.data); // Load Awal
      setLoading(false);
    } catch (error) {
      console.log("error artikel all :>> ", error);
      // setError(true);
    }
  };

  useEffect(() => {
    fetchArtikelAll();

    return () => {
      setAllArtikel([]); // Clear saat load awal
    };
  }, []);

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    let result = allArtikel.filter(
      (data) => data.judul_artikel.toLowerCase().search(value) !== -1
    );
    setFilteredData(result);
  };

  return (
    <div className="artikel">
      <Helmet>
        <title>Media | Adem Sari</title>
        <meta
          name="description"
          content="Artikel Adem Sari berisi informasi menarik tentang Adem Sari"
        />
      </Helmet>
      <DaunJatuh />
      <GoToTop />
      <div className="artikel-container">
        <InfoKamus
          title="Media"
          allArtikel={filteredData}
          loading={loading}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
}

export default Artikel;
