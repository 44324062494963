import { Helmet } from "react-helmet-async";
import "./kebijakan-kami.css";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";

function KebijakanKami() {
  return (
    <div className="kebijakan-kami">
      <Helmet>
        <title>Kebijakan Kami | Adem Sari</title>
        <meta
          name="description"
          content="Kebijakan kami Adem Sari meliputi beberapa hal"
        />
      </Helmet>

      <DaunJatuh />
      <GoToTop />
      <div className="kebijakan-kami-container">
        <div className="kebijakan-title">
          <h3>Kebijakan Kami</h3>
        </div>
        <div className="kebijakan-kami-wrapper">
          <p>
            Kami berkomitmen menjaga privasi seluruh konsumen yang mengunjungi
            website Adem Sari. Berikut adalah praktik yang kami jalankan untuk
            menjalankan komitmen tersebut:
          </p>
          <p className="list"> 1. Kebijakan Privasi </p>
          <p className="detail__lists">
            Kami mengumpulkan informasi pribadi Anda melalui beberapa cara; dari
            proses interaksi yang Anda lakukan bersama kami, laman produk,
            layanan, dan lain-lain. Semua data yang kami terima akan dijaga
            sebaik-baiknya. Kami tidak akan menyebarluaskan, menyewakan, ataupun
            menjual data-data pribadi Anda.
          </p>
          <p className="list">2. Mentaati Hukum yang Berlaku </p>
          <p className="detail__lists">
            Kami menjamin jenis data yang dikumpulkan, proses pengumpulan,
            penyewaan, dan penggunaannya telah sesuai dengan hukum yang berlaku
            di Indonesia.
          </p>
        </div>
      </div>
    </div>
  );
}

export default KebijakanKami;
