import React, { useContext, useEffect, useMemo } from "react";
import { Context } from "../../context/Context";
import "./intro.css";
import { useWindow } from "../../helpers/useWindow";

function Intro() {
  const { isPlay } = useContext(Context);
  let waterfall1 = useMemo(
    () => new Audio("/assets/images/waterfall-1.mp3"),
    []
  );

  let burung = useMemo(() => new Audio("/assets/images/burung-2.mp3"), []);

  if (isPlay) {
    waterfall1.play();
    burung.play();
    waterfall1.muted = false;
    burung.muted = false;
  } else {
    waterfall1.pause();
    burung.pause();
    waterfall1.muted = true;
    burung.muted = true;
  }

  useEffect(() => {
    const handleOut = () => {
      waterfall1.volume = 0;
      burung.volume = 0;
    };

    const handleOver = () => {
      waterfall1.volume = 0.5;
      burung.volume = 1;
    };

    const intro = document.querySelector(".intro");
    intro.addEventListener("mouseout", handleOut);
    intro.addEventListener("mouseover", handleOver);

    return () => {
      intro.removeEventListener("mouseout", handleOut);
      intro.removeEventListener("mouseover", handleOver);
    };
  }, [burung, waterfall1]);

  // SET MOBILE
  let layer = useWindow();

  let imageBackground;
  if (layer >= 768) {
    imageBackground = "/assets/images/BG_1.webp";
  } else {
    imageBackground = "/assets/images/BG_1mobile.png";
  }

  return (
    <div className="intro">
      <img src={imageBackground} alt="intro" />
      <div className="logo-utama">
        <img src="/assets/images/logo-utama.png" alt="" />
      </div>
      <div className="air-terjun">
        <img src="/assets/images/air-terjun-11.gif" alt="" />
      </div>
      <div className="lebah">
        <img src="/assets/images/Lebah.gif" alt="" />
      </div>
    </div>
  );
}

export default Intro;
