import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./detail-produk.css";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import Slider from "../../components/slider/Slider";
import Buy from "../../components/buy/Buy";
import GoToTop from "../../components/goToTop/goToTop";
import Loading from "../../components/loading/Loading";
import instance from "../../config/axios";

function DetailProduk() {
  const navigate = useNavigate();
  const [deskripsi, setDeskripsi] = useState(true);
  const [manfaat, setManfaat] = useState(false);
  const [buy, setBuy] = useState(false);
  const [buyTerkait, setBuyTerkait] = useState(false);

  // SET STATE DETAIL PRODUK
  const [detailProduk, setDetailProduk] = useState(null);
  const [loading, setLoading] = useState(false);
  const [relatedProduk, setRelatedProduk] = useState(null);
  const [loadingTerkait, setLoadingTerkait] = useState(false);
  // console.log("relatedProduk :>> ", relatedProduk);

  // EFFECT DETAIL PRODUK
  const { slug } = useParams();
  const fetchDetailProduk = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.get(`api/produk/${slug}`);

      setLoading(false);
      setDetailProduk(res.data);
    } catch (error) {
      console.log("error.respoonse :>> ", error.response);
    }
  }, [slug]);

  // EFFECT RELATED PRODUK

  const fetchRelatedlProduk = useCallback(async () => {
    setLoadingTerkait(true);
    try {
      const res = await instance.get(`api/relatedproduk/${slug}`);
      setLoadingTerkait(false);
      setRelatedProduk(res.data);
    } catch (error) {
      console.log("error.respoonse :>> ", error.response);
    }
  }, [slug]);

  useEffect(() => {
    fetchDetailProduk();
    return () => {
      setDetailProduk(null);
    };
  }, [fetchDetailProduk]);

  useEffect(() => {
    fetchRelatedlProduk();
    return () => {
      setRelatedProduk(null);
    };
  }, [fetchRelatedlProduk]);

  return (
    <>
      <div className="detail-produk">
        <Helmet>
          <title>{`${detailProduk?.nama_produk} | Adem Sari`} </title>

          <meta name="description" content={`${detailProduk?.excerpt}`} />
        </Helmet>

        <DaunJatuh />

        <div className="detail-produk-container">
          {loading && <Loading loading={loading} />}
          {detailProduk && (
            <>
              <div className="detail-produk-title">
                <h3>{detailProduk.nama_produk}</h3>
              </div>
              <div className="detail-produk-konten">
                <div className="detail-slider">
                  <Slider fotos={detailProduk.all_foto} />
                </div>
                <div className="detail-deskripsi">
                  <p>{detailProduk.excerpt}</p>
                  <div className="detail-tab">
                    <button
                      onClick={() => {
                        setManfaat(false);
                        setDeskripsi(true);
                      }}
                      className={`${deskripsi && "active"}`}
                    >
                      Deskripsi
                    </button>
                    <button
                      onClick={() => {
                        setManfaat(true);
                        setDeskripsi(false);
                      }}
                      className={`${manfaat && "active"}`}
                    >
                      Manfaat
                    </button>
                    <button onClick={() => setBuy(true)}>
                      <img src="/assets/images/icon-belanja.svg" alt="" />
                      <span>Beli Sekarang</span>
                    </button>
                  </div>
                  {/* Deskripsi */}
                  {deskripsi && (
                    <div className="detail-tab-deskripsi">
                      {/* <table>
                      <tbody>
                        <tr>
                          <td>Sertifikat</td>
                          <td>:</td>
                          <td>Halal</td>
                        </tr>
                        <tr>
                          <td>Kondisi</td>
                          <td>:</td>
                          <td>Baru</td>
                        </tr>
                        <tr>
                          <td>Berat</td>
                          <td>:</td>
                          <td>300 Gram</td>
                        </tr>
                      </tbody>
                    </table> */}
                      <p>{detailProduk.desc_produk}</p>
                    </div>
                  )}
                  {/* Manfaat */}
                  {manfaat && (
                    <div className="detail-tab-deskripsi">
                      <p>{detailProduk.manfaat_produk}</p>
                    </div>
                  )}
                  {/* Buy */}
                  {buy && (
                    <Buy
                      type="produk"
                      setBuy={setBuy}
                      ecommerce={detailProduk.ecommerce_produk}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          <div className="detail-produk-title produk-terkait">
            <h3>Produk Terkait</h3>
          </div>
          {loadingTerkait && <Loading loading={loadingTerkait} />}
          <div className="detail-produk-kontents">
            {relatedProduk &&
              relatedProduk.produk_terkait.map((related) => (
                <div key={related.id_produk} style={{ overflow: "hidden" }}>
                  <div className="detail-produk-kontents-wrapper">
                    <div className="foto-detail-produk">
                      <img
                        src={related.foto_produk}
                        alt={related.nama_produk}
                        className="sachet"
                      />
                    </div>
                    <div className="nama-detail-produk">
                      <h5>{related.nama_produk}</h5>
                    </div>
                    <div className="button-detail-produk">
                      <div className="image-wrapper">
                        <img
                          src="/assets/images/see.svg"
                          alt=""
                          onClick={() => navigate(`/produk/${related.slug}`)}
                        />
                        <img
                          src="/assets/images/cart.svg"
                          alt=""
                          onClick={() => setBuyTerkait(true)}
                        />
                      </div>
                    </div>
                  </div>
                  {buyTerkait && (
                    <Buy
                      type="produk"
                      setBuy={setBuyTerkait}
                      ecommerce={related.ecommerce_produk}
                    />
                  )}
                </div>
              ))}
            {/* <div className="detail-produk-kontents-wrapper">
                <div className="foto-detail-produk">
                  <img
                    src="/assets/images/chingku.png"
                    alt=""
                    className="chingku"
                  />
                </div>
                <div className="nama-detail-produk">
                  <h5>ADEM SARI CHINGKU HERBAL TEA</h5>
                </div>
                <div className="button-detail-produk">
                  <div className="image-wrapper">
                    <img src="/assets/images/see.svg" alt="" />
                    <img src="/assets/images/cart.svg" alt="" />
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>

      <GoToTop />
    </>
  );
}

export default DetailProduk;
