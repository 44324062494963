import NavItem from "../NavItem/NavItem";
import "./navmobile.css";

function NavMobile({ setOpen }) {
  return (
    <div className="navmobile">
      <NavItem setOpen={setOpen} />
    </div>
  );
}

export default NavMobile;
