import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./produk.css";
import Buy from "../../components/buy/Buy";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import Loading from "../../components/loading/Loading";
import GoToTop from "../../components/goToTop/goToTop";
import instance from "../../config/axios";

function Produk() {
  const [openBuy, setOpenBuy] = useState(false);
  let navigate = useNavigate();
  // USESTATE FETCH
  // const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allProduk, setAllProduk] = useState([]);
  const [fiteredData, setFilteredData] = useState(allProduk);
  // console.log("fiteredData :>> ", fiteredData);

  // FETCH PRODUK LIMIT
  // setLoading(true);
  const fetcAllProduk = async () => {
    setLoading(true);
    try {
      const res = await instance.get("api/produk/all");
      setAllProduk(res.data);
      setFilteredData(res.data); // Load Awal
      setLoading(false);
    } catch (error) {
      console.log("error allProduk :>> ", error);
      // setError(true);
    }
  };
  // Search
  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    let result = allProduk.filter(
      (data) => data.nama_produk.toLowerCase().search(value) !== -1
    );
    setFilteredData(result);
  };

  useEffect(() => {
    fetcAllProduk();

    return () => {
      setAllProduk([]); // Clear
    };
  }, []);
  return (
    <>
      <div className="produk">
        <Helmet>
          <title>Produk | Adem Sari</title>
          <meta
            name="description"
            content="Produk Adem Sari Terbuat dari bahan terbaik"
          />
        </Helmet>

        <GoToTop />
        <DaunJatuh />
        <div className="produk-container">
          <div className="produk-header">
            <div className="produk-title">
              <h3>Produk</h3>
            </div>
            <div className="produk-cari">
              <input
                type="text"
                placeholder="Cari produk"
                onChange={(e) => handleSearch(e)}
              />
              <img src="/assets/images/Vector.svg" alt="" />
            </div>
          </div>
          {/* ISI PRODUK */}
          <div className="produk-kontents">
            {loading && <Loading loading={loading} />}
            {/* Filter Array Kosong */}
            {fiteredData.length === 0 && !loading && (
              <div className="tidak-ditemukan-wrapper">
                <p>Produk tidak ditemukan :)</p>
              </div>
            )}

            {fiteredData &&
              fiteredData.map((produk) => (
                <div key={produk.id_produk}>
                  <div
                    className="produk-kontents-wrapper"
                    key={produk.id_produk}
                  >
                    <div className="foto-produk">
                      <img
                        src={produk.foto_produk}
                        alt={produk.nama_produk}
                        // className="sparkling"
                      />
                    </div>
                    <div className="nama-produk">
                      <h5>{produk.nama_produk}</h5>
                    </div>
                    <div className="button-produk">
                      <div className="image-wrapper">
                        <img
                          src="/assets/images/see.svg"
                          alt=""
                          onClick={() => navigate(`/produk/${produk.slug}`)}
                        />
                        <img
                          src="/assets/images/cart.svg"
                          alt=""
                          onClick={() => setOpenBuy(true)}
                        />
                      </div>
                    </div>
                  </div>
                  {openBuy && (
                    <Buy
                      type="produk"
                      ecommerce={produk.ecommerce_produk}
                      setBuy={setOpenBuy}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Produk;
