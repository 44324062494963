import { useEffect, useState, useContext, useMemo } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./info-kamu.css";
import InfoKamus from "./InfoKamus";
import { Context } from "../../context/Context";
import { useWindow } from "../../helpers/useWindow";
import instance from "../../config/axios";

function InfoKamu() {
  // USESTATE FETCH
  // const [error, setError] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [allArtikel, setAllArtikel] = useState([]);

  // FETCH ARTIKEL ALL
  // setLoading(true);
  const fetchArtikelAll = async () => {
    try {
      const res = await instance.get("api/artikel/all/new");
      setAllArtikel(res.data);
      // setLoading(false);
    } catch (error) {
      console.log("error artikel all :>> ", error);
      // setError(true);
    }
  };

  useEffect(() => {
    fetchArtikelAll();
  }, []);

  // GSAP SCROLL
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      ".info-kamu",
      {
        y: 120,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: document.querySelector(".produk-kami"),
          start: "center center",
          end: "bottom center",
          scrub: true,
        },
      }
    );
  }, []);
  // Audio
  const { isPlay } = useContext(Context);
  let forest = useMemo(() => new Audio("/assets/images/Forest-4.mp3"), []);

  if (isPlay) {
    forest.play();
    forest.muted = false;
  } else {
    forest.pause();
    forest.muted = true;
  }

  useEffect(() => {
    const handleOut = () => {
      forest.volume = 0;
    };

    const handleOver = () => {
      forest.volume = 1;
    };

    const intro = document.querySelector(".info-kamu");
    if (intro) {
      intro.addEventListener("mouseout", handleOut);
      intro.addEventListener("mouseover", handleOver);
    }

    const kontak = document.querySelector(".kontak-footer");
    if (kontak) {
      kontak.addEventListener("mouseout", handleOut);
      kontak.addEventListener("mouseover", handleOver);
    }

    return () => {
      if (intro) {
        intro.removeEventListener("mouseout", handleOut);
        intro.removeEventListener("mouseover", handleOver);
      }
      if (kontak) {
        kontak.removeEventListener("mouseout", handleOut);
        kontak.removeEventListener("mouseover", handleOver);
      }
    };
  }, [forest]);

  // SET MOBILE
  let layer = useWindow();

  let imageBackground;
  if (layer >= 768) {
    imageBackground = "/assets/images/BG_4.webp";
  } else {
    imageBackground = "/assets/images/BG_4mobile.png";
  }

  return (
    <div className="info-kamu">
      <img src={imageBackground} alt="info" />
      <InfoKamus title="Info Untuk Kamu" allArtikel={allArtikel} />
    </div>
  );
}

export default InfoKamu;
