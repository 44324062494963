import { useState } from "react";
import "./kontak-footer.css";
import { useSnackbar } from "notistack";
// import BounceLoader from "react-spinners/BounceLoader";
import { useWindow } from "../../helpers/useWindow";
import instance from "../../config/axios";

function KontakFooter() {
  const [nama, setNama] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [nohp, setNoHp] = useState("");
  // console.log("nama,gender,email,phone :>> ", nama, gender, email, phone);

  const [openForm, setOpenForm] = useState(true);
  const [, setBerhasil] = useState("");
  // const [loading, setLoading] = useState(true);
  // console.log(berhasil);
  //SnackBar test
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    try {
      if (nama.length >= 5) {
        if (email.length > 7 && email.includes("@")) {
          if (nohp.length > 10 && nohp.length < 15 && isNaN(nohp) === false) {
            const res = await instance.post("api/register", {
              nama,
              email,
              password,
              nohp,
            });
            setOpenForm(false);
            // setLoading(false);
            setBerhasil(res.data);
            // console.log("res :>> ", res.data.msg);
            enqueueSnackbar("berhasil daftar", {
              variant: "success",
              anchorOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
            });
          } else {
            enqueueSnackbar("Nomor ponsel diisi yang valid (10-15 digit)", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Email harus yang valid", { variant: "error" });
        }
      } else {
        enqueueSnackbar("Minimal panjang nama 5 karakter", {
          variant: "error",
        });
      }
    } catch (error) {
      // console.log("error :>> ", error.response);
      enqueueSnackbar(error.response.data.error, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  };

  // SET MOBILE
  let layer = useWindow();

  let imageBackground;
  if (layer >= 768) {
    imageBackground = "/assets/images/BG_5.webp";
  } else {
    imageBackground = "/assets/images/BG_5mobile.png";
  }

  return (
    <div className="kontak-footer">
      <img src={imageBackground} alt="kontak-footer" />
      {/* Form Kontak */}
      <div className="kontak-footer-container">
        {openForm ? (
          <div className="kontak-footer-contents">
            <form onSubmit={handleSubmit}>
              <div className="form-kontents">
                <div className="group-control">
                  <label htmlFor="nama">Nama</label>
                  <input
                    type="text"
                    id="nama"
                    placeholder="Nama Kamu"
                    onChange={(e) => setNama(e.target.value)}
                  />
                  <label htmlFor="password">Kata Sandi</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Kata Sandi Kamu"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <label htmlFor="jenis-kelamin">Jenis Kelamin</label>
                  <select
                    name=""
                    id="jenis-kelamin"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="perempuan">Perempuan</option>
                    <option value="laki-laki">Laki - Laki</option>
                  </select> */}
                </div>
                <div className="group-control">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email Kamu"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="nomor-telepon">Nomor Telepon</label>
                  <input
                    type="text"
                    id="nomor-telepon"
                    placeholder="Nomor Telepon"
                    onChange={(e) => setNoHp(e.target.value)}
                  />
                </div>
              </div>
              <div className="button-kirim">
                <button type="submit">Daftar</button>
              </div>
            </form>
          </div>
        ) : (
          <div className="berhasil-wrapper">
            {/* <BounceLoader color={"#36D7B7"} loading={loading} size={150} /> */}
            <p>berhasil daftar!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default KontakFooter;
