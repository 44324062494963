import { Helmet } from "react-helmet-async";
import "./tentang-kami.css";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";

function TentangKami() {
  return (
    <div className="tentang-kami">
      <GoToTop />
      <Helmet>
        <title>Tentang Kami | Adem Sari</title>
        <meta
          name="description"
          content="Tentang Kami Adem Sari yang sudah berpengalaman puluhan tahun"
        />
      </Helmet>
      <DaunJatuh />
      <div className="tentang-kami-container">
        <div className="tentang-kami-title">
          <span>
            "Grup Enesis bertekad untuk menciptakan produk-produk baru dan
            memperluas pasar baik secara lokal maupun global. Ekspansi teranyar
            Enesis meliputi proyek penetrasi pasar di China, Vietnam, Thailand,
            dan Amerika Serikat."
          </span>
        </div>
      </div>
    </div>
  );
}

export default TentangKami;
