import { createContext, useReducer } from "react";
import Reducer, { AuthReducer } from "./Reducer";

const INITIAL_STATE = {
  isPlay: false,
};

export const Context = createContext(INITIAL_STATE);

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);
  //   console.log(`dispatch`, dispatch);
  return (
    <Context.Provider
      value={{
        isPlay: state.isPlay,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const AUTH_STATE = {
  isLogin: false,
  isLoading: false,
  user: null,
};
export const AuthContext = createContext(AUTH_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, AUTH_STATE);
  const setLogin = (data) => {
    dispatch({
      type: "SET_LOGIN",
      data,
    });
  };
  const setUser = (data) => {
    dispatch({
      type: "SET_USER",
      data,
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        setLogin,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
