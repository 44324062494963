import "./loading.css";
import ClipLoader from "react-spinners/ClipLoader";

function Loading({ loading }) {
  return (
    <div className="loading-wrapper">
      <ClipLoader color={"#FFEB00"} loading={loading} size={100} />
    </div>
  );
}

export default Loading;
