import { Link, useNavigate } from "react-router-dom";
import { FormatDate } from "../../helpers/formatDate";
import Loading from "../loading/Loading";

function InfoKamus({ title, allArtikel, loading, handleSearch }) {
  const navigate = useNavigate();
  // let slug = "kesehatan-baik";
  // console.log("allArtikel :>> ", allArtikel);
  return (
    <>
      <div className="info-kamu-container">
        <div
          className={`info-kamu-contents ${title === "Media" && "titleBlock"}`}
        >
          <h3>{title}</h3>
          {title === "Info Untuk Kamu" && (
            <h4>
              <Link to="/media">
                Lihat semua <img src="/assets/images/panah.svg" alt="" />
              </Link>
            </h4>
          )}
          {title === "Media" && (
            <div className="produk-cari">
              <input
                type="text"
                placeholder="Cari artikel"
                onChange={(e) => handleSearch(e)}
              />
              <img src="/assets/images/Vector.svg" alt="" />
            </div>
          )}
        </div>
      </div>
      <div
        className={`info-kamu-container-produk ${
          title === "Media" && "heightAuto"
        }`}
      >
        <div className="info-kamu-contents-produk">
          {loading && <Loading loading={loading} />}
          {/* Filter Array Kosong */}
          {allArtikel.length === 0 && !loading && (
            <div className="tidak-ditemukan-wrapper">
              <p>Artikel terkait tidak ditemukan :)</p>
            </div>
          )}
          {allArtikel &&
            allArtikel.map((artikel) => (
              <div className="info-kamu-produk-group" key={artikel.id_artikel}>
                <div className="image-info">
                  <img
                    src={artikel.gambar_artikel}
                    alt={artikel.judul_artikel}
                  />
                </div>
                <div className="konten-info">
                  <div className="span">#{artikel.type_artikel}</div>
                  <div className="judul">{artikel.judul_artikel}</div>
                  <div className="span">
                    {FormatDate(artikel.tanggal_publish)}
                  </div>
                  <div className="summary">{artikel.meta_desc}</div>
                  <div
                    className="continue"
                    onClick={() =>
                      navigate(`/media/${artikel.type_artikel}/${artikel.slug}`)
                    }
                  >
                    Continue Reading...
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="info-kamu-produk-group">
            <div className="image-info">
              <img src="/assets/images/Mask-Group.png" alt="" />
            </div>
            <div className="konten-info">
              <div className="span">#Healthy</div>
              <div className="judul">
                Apakah Panas Dalam Merupakan Gejala Covid-19?
              </div>
              <div className="span">30 Agustus 2021</div>
              <div className="summary">
                Pandemi COVID-19 yang sudah berjalan lebih dari 1 tahun ini
                ternyata membuat virusnya mengalami mutasi yang cukup
                mengkhawatirkan beberapa orang...
              </div>
              <div className="continue">Continue Reading...</div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default InfoKamus;
