import { Helmet } from "react-helmet-async";
import "./hubungi-kami.css";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";

function HubungiKami() {
  return (
    <div className="hubungi-kami">
      <Helmet>
        <title>Hubungi Kami | Adem Sari</title>
        <meta
          name="description"
          content="Hubungi kami Adem Sari melalui kontak yang tertera"
        />
      </Helmet>

      <DaunJatuh />
      <GoToTop />
      <div className="hubungi-kami-container">
        {/* Alamat */}
        <div className="hubungi-kami-alamat-wrapper">
          <div className="hubungi-kami-alamat-kontent">
            <div className="detail">
              <div className="image-detail">
                <img src="/assets/images/location.svg" alt="" />
              </div>
              <div className="alamat-detail">
                <h4>Alamat</h4>
                <p>PT. Sari Enesis Indah</p>
                <p>Jl. Kruing I Blok L5 No. 5</p>
                <p>Delta Silicon Industrial Estate, Bekasi 17550</p>
              </div>
            </div>
            <div className="detail">
              <div className="image-detail">
                <img src="/assets/images/call2.svg" alt="" />
              </div>
              <div className="alamat-detail">
                <h4>Telepon</h4>
                <p>(+62) 800 128 2828 (bebas pulsa)</p>
              </div>
            </div>
            <div className="detail">
              <div className="image-detail">
                <img src="/assets/images/Whatsapp.png" alt="" />
              </div>
              <div className="alamat-detail">
                <h4>Whatsapp</h4>
                <p>(+62) 878 5256 0088</p>
              </div>
            </div>
            <div className="detail">
              <div className="image-detail">
                <img src="/assets/images/mail.svg" alt="" />
              </div>
              <div className="alamat-detail">
                <h4>Email</h4>
                <p>customer.service@enesis.com</p>
              </div>
            </div>
          </div>
        </div>
        {/* FORM */}
        <div className="hubungi-kami-kontents-wrapper">
          <div className="hubungi-kami-title">
            <h4>Kirim Pesan</h4>
          </div>
          <form className="form-hubungi-kami">
            <div className="group-hubungi-kami">
              <label htmlFor="nama">Nama</label>
              <input type="text" id="nama" placeholder="Nama kamu" />
            </div>
            <div className="group-hubungi-kami">
              <label htmlFor="email">Email</label>
              <input type="text" id="email" placeholder="Email kamu" />
            </div>
            <div className="group-hubungi-kami">
              <label htmlFor="nomor-telepon">Nomor Telepon</label>
              <input
                type="text"
                id="nomor-telepon"
                placeholder="Nomor Telepon kamu"
              />
            </div>
            <div className="group-hubungi-kami">
              <label htmlFor="pesan">Pesan</label>
              <input type="text" id="pesan" placeholder="Pesan kamu" />
            </div>
            <div className="button-wrapper-hubungi-kami">
              <button type="submit">Kirim</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HubungiKami;
