import { useCallback, useEffect, useState } from "react";
import "./pop-up-produk.css";
import Loading from "../loading/Loading";
import instance from "../../config/axios";

function PopUpProduk({ setTypeProduk, typeProduk }) {
  // USESTATE FETCH
  const [loading, setLoading] = useState(false);
  const [allProduk, setAllProduk] = useState([]);
  // console.log("allProduk :>> ", allProduk);

  // FETCH PRODUK POPUP
  // setLoading(true);

  const fetchPopUpProduk = useCallback(async () => {
    // GET ALL PRODUK
    setLoading(true);
    const res = await instance.get(`api/produk/type/${typeProduk}`);
    try {
      setAllProduk(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error allProduk :>> ", error);
      setLoading(false);
    }
  }, [typeProduk]);

  useEffect(() => {
    fetchPopUpProduk();

    return () => {
      setAllProduk([]); // Clear
    };
  }, [fetchPopUpProduk]);

  return (
    <div className="pop-up-produk">
      <div className="pop-up-produk-wrapper">
        <div className="pop-wrapper-img" onClick={() => setTypeProduk("")}>
          <img src="/assets/images/x.svg" alt="" />
        </div>

        <div className="pop-up-kontainer">
          {loading && <Loading loading={loading} />}
          {allProduk.map((produk) => (
            <div className="pop-up-wrapper" key={produk.id_produk}>
              <img
                className="img-pop-up-produk"
                src={produk.foto_produk}
                alt={produk.nama_produk}
              />
              <span className="span-produk">{produk.nama_produk}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PopUpProduk;
