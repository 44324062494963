import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { Context } from "../../context/Context";
import "./navbar.css";
import Buy from "../buy/Buy";
import Layout from "../layout/Layout";
import NavItem from "../NavItem/NavItem";
import NavMobile from "../navmobile/NavMobile";
import instance from "../../config/axios";

function Navbar() {
  // Navbar Dinamis
  const [solid, setSolid] = useState(false);
  const [buyNav, setBuyNav] = useState(false);
  const navbar = solid ? "navbar solid" : "navbar";
  // STATE BUY NAV ECOMMERCE
  const [ecommerce, setEcommerce] = useState([]);

  // OPEN MENU NAVBAR
  const [open, setOpen] = useState(false);

  // STATE PLAY AUDIO
  // const [isPlay, setIsPlay] = useState(false);
  const { dispatch, isPlay } = useContext(Context);
  // console.log("dispatch :>> ", dispatch);

  // EFFECT ECOMMERCE
  useEffect(() => {
    const fetchEcommerce = async () => {
      const res = await instance.get("api/ecommerce");
      setEcommerce(res.data);
    };

    fetchEcommerce();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 120;
      if (show) {
        setSolid(true);
      } else {
        setSolid(false);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`${navbar} ${open && "navjo"}`}>
        <Layout>
          <div className="logo">
            <Link to="/">
              <img src="/assets/images/logo.png" alt="" />
            </Link>
          </div>
          <div className="navigasi">
            <NavItem setOpen={setOpen} />
          </div>
          <div className="button-belanja">
            <button onClick={() => setBuyNav(true)}>
              <img src="/assets/images/icon-belanja.svg" alt="" />
              <span className="btn-title">Belanja</span>
            </button>
          </div>
          <div className="button-play">
            {isPlay ? (
              <button onClick={() => dispatch({ type: "OFF" })}>
                <VolumeUp />
              </button>
            ) : (
              <button onClick={() => dispatch({ type: "ON" })}>
                <VolumeOff />
              </button>
            )}
          </div>
          <div className="button-menu">
            {open ? (
              <button onClick={() => setOpen(!open)}>
                <img src="/assets/images/close.svg" alt="" />
              </button>
            ) : (
              <button onClick={() => setOpen(!open)}>
                <img src="/assets/images/open.svg" alt="" />
              </button>
            )}
          </div>
        </Layout>
      </div>
      {buyNav && (
        <Buy type="official" setBuy={setBuyNav} ecommerce={ecommerce} />
      )}
      {open && <NavMobile setOpen={setOpen} />}
    </>
  );
}

export default Navbar;
