import { useCallback, useEffect, useState } from "react";
import "./slider.css";

function Slider({ fotos }) {
  const [slideIdx, setSlideIdx] = useState(1);

  const currentSlide = (index) => {
    showSlides(setSlideIdx(index));
  };

  const showSlides = useCallback(
    (index) => {
      const slides = document.getElementsByClassName("mySlides");
      const dots = document.getElementsByClassName("dot");

      // console.log("slides :>> ", slides);

      if (index > slides.length) {
        // slideIndex = 1;
        setSlideIdx(1);
      }
      if (index < 1) {
        // slideIndex = slides.length;
        setSlideIdx(slides.length);
      }

      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      for (let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      if(fotos.length > 0) {

        slides[slideIdx - 1].style.display = "block";
        dots[slideIdx - 1].className += " active";
      }
    },
    [slideIdx, fotos.length]
  );

  useEffect(() => {
    showSlides(slideIdx);

    const timer = setTimeout(() => {
      if (slideIdx < 3) {
        setSlideIdx(slideIdx + 1);
      } else {
        setSlideIdx(1);
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [slideIdx, showSlides]);

  return (
    <div className="slideshow-container">
      {fotos.map((foto, idx) => (
        <div key={idx} className="mySlides fade">
          <img src={foto.foto_produk} alt={"Produk"} />
        </div>
      ))}
      {/* // <div className="mySlides fade">
      //   <img src={fotos} alt="sachet" />
      // </div>
      // <div className="mySlides fade">
      //   <img src={fotos} alt="sachet" />
      // </div>
      // <div className="mySlides fade">
      //   <img src={fotos} alt="sachet" />
      // </div> */}
      {/* The Dots */}
      <div className="dots">
        {fotos.map((foto, idx) => (
          <span
            className="dot"
            key={idx}
            onClick={() => currentSlide(idx + 1)}
          ></span>
        ))}
        {/* <span className="dot" onClick={() => currentSlide(2)}></span>
        <span className="dot" onClick={() => currentSlide(3)}></span> */}
      </div>
    </div>
  );
}

export default Slider;
