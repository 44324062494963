import { NavLink, useNavigate } from "react-router-dom";
import "./navitem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import { useContext } from "react";
// import { AuthContext } from "../../context/Context";

function NavItem({ setOpen }) {
  const navigate = useNavigate();
  const nama = localStorage.getItem("nama");
  // const { isLogin, user } = useContext(AuthContext);
  // console.log(user);
  const handleLogout = () => {
    localStorage.removeItem("nama");
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleProfil = () => {
    navigate("/profil");
  };

  return (
    <ul>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/">Beranda</NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/produk">Produk</NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/tentang-kami">Tentang Kami</NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/media">Media</NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        <NavLink to="/promo">Promo</NavLink>
      </li>
      <li onClick={() => setOpen(false)}>
        {nama !== null ? (
          <div className="dropdown">
            <button className="dropbtn">
              {nama} <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className="dropdown-content">
              <span onClick={handleProfil}>Profil</span>
              <span onClick={handleLogout}>Logout</span>
            </div>
          </div>
        ) : (
          <NavLink to="/login">Masuk</NavLink>
        )}
      </li>
    </ul>
  );
}

export default NavItem;
